import EmptyState from '@aurora/shared-client/components/common/EmptyState/EmptyState';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import {
  PagerLoadMoreVariant,
  PagerPosition,
  PagerVariant
} from '@aurora/shared-client/components/common/Pager/enums';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import PageContext from '@aurora/shared-client/components/context/PageContext/PageContext';
import type { TextVariantsContextInterface } from '@aurora/shared-client/components/context/TextVariantContext';
import TextVariantContext from '@aurora/shared-client/components/context/TextVariantContext';
import nodeViewsQuery from '@aurora/shared-client/components/nodes/NodeViews.query.graphql';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { UserScope } from '@aurora/shared-client/types/enums';
import type {
  CoreNode,
  CoreNodeConstraints,
  CoreNodeSorts,
  User
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ConversationStyle,
  SortDirection
} from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  AuthUserFragment,
  ContextNodeFragment,
  ContextUserFragment,
  NodeBasicFieldsFragment
} from '@aurora/shared-generated/types/graphql-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import {
  EndUserComponent,
  EndUserPages,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import {
  merge,
  UndefinedValueMergeBehavior
} from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import React, { useContext, useEffect, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { ApolloQueryCacheKey, ItemType, NodeViewVariant } from '../../../types/enums';
import type {
  NodeViewFragment,
  NodeViewsQuery,
  NodeViewsQueryVariables
} from '../../../types/graphql-types';
import PaneledItemList from '../../common/List/PaneledItemList/PaneledItemList';
import EditableWidget from '../../common/Widget/EditableWidget';
import type { ComponentCommonProps, WidgetFC, WidgetProps } from '../../common/Widget/types';
import type { ItemViewTypeAndProps } from '../../entities/types';
import { GroupHubSortMenuItem } from '../../grouphubs/GroupHubListSortsAndFilters/GroupHubListSortsAndFilters';
import type { NodeViewCardProps, NodeViewInlineProps } from '../../nodes/NodeView/types';
import type { NodeListType } from '../../nodes/types';
import useEntityViewQuery from '../../useEntityViewQuery';
import useTranslation from '../../useTranslation';
import { PlacesSortMenuItem, PlacesType } from '../enums';

export const nodeViewCardInlineDefaults: NodeViewInlineProps | NodeViewCardProps = {
  useNodeAvatar: true,
  useNodeDescription: true,
  descriptionClampLines: 1,
  useNodeTopicsCount: true,
  useNodeLatestActivityTime: true,
  useUnreadMessagesCount: true
};

/**
 * Get the node type constraint for the selected place type and the context node
 * @param placeType Type of place selected
 * @param contextNode The context node
 * @param pageName
 * @param user
 * @returns Node type constraint depending on the selected place
 */
export function getNodeTypeConstraint(
  placeType: PlacesType,
  contextNode: ContextNodeFragment,
  pageName: EndUserPages,
  user: AuthUserFragment | ContextUserFragment
): CoreNodeConstraints | null {
  const nodeTypes: Array<string> = Object.values(NodeType);
  if (pageName === EndUserPages.UserPage || placeType === PlacesType.MY_GROUPHUB) {
    return {
      nodeType: { eq: PlacesType.GROUPHUB },
      userId: { eq: user.id },
      depth: {},
      parentId: {},
      ancestorId: { eq: contextNode.id }
    };
  } else if (placeType === PlacesType.ALL) {
    return null;
  } else if (contextNode.nodeType === NodeType.GROUPHUB) {
    return { nodeType: { eq: PlacesType.BOARD } };
  } else if (nodeTypes.includes(placeType)) {
    return { nodeType: { eq: placeType } };
  } else {
    return {
      conversationStyle: {
        eq: getAsEnum(placeType, ConversationStyle)
      }
    };
  }
}

/**
 * Function to get the sorting options
 * @param placeType the type of place selected
 */
export function getSortOptionValues(placeType) {
  return placeType === PlacesType.GROUPHUB || placeType === PlacesType.MY_GROUPHUB
    ? [
        {
          key: 'title',
          value: { title: { direction: SortDirection.Asc } }
        },
        {
          key: GroupHubSortMenuItem.CREATED_DATE_ASC,
          value: { creationDate: { direction: SortDirection.Asc } }
        },
        {
          key: GroupHubSortMenuItem.CREATED_DATE_DESC,
          value: { creationDate: { direction: SortDirection.Desc } }
        },
        {
          key: GroupHubSortMenuItem.LATEST_ACTIVITY,
          value: {
            messageActivityCorePropertyChangeTime: { direction: SortDirection.Desc }
          }
        },
        {
          key: GroupHubSortMenuItem.MEMBER_COUNT,
          value: { membersCount: { direction: SortDirection.Desc } }
        }
      ]
    : [
        {
          key: PlacesSortMenuItem.TITLE,
          value: { title: { direction: SortDirection.Asc } }
        },
        {
          key: PlacesSortMenuItem.LATEST_ACTIVITY,
          value: {
            messageActivityCorePropertyChangeTime: { direction: SortDirection.Desc }
          }
        },
        {
          key: PlacesSortMenuItem.VIEWS,
          value: { views: { direction: SortDirection.Desc } }
        },
        {
          key: PlacesSortMenuItem.TOPICS_COUNT,
          value: { topicsCount: { direction: SortDirection.Desc } }
        },
        {
          key: PlacesSortMenuItem.MESSAGES_COUNT,
          value: { messagesCount: { direction: SortDirection.Desc } }
        },
        {
          key: PlacesSortMenuItem.COMMUNITY_STRUCTURE,
          value: {
            communityStructure: { direction: SortDirection.Asc, order: 0 },
            position: {
              direction: SortDirection.Asc,
              order: 1
            }
          }
        }
      ];
}

/**
 * This function returns the current user scope
 * @param queryVariables the node query variable
 * @param currentUser the authenticated user
 * @param currentTextContext the text variant context
 */
export function getTextVariantForPlacesQueryVariables(
  queryVariables: NodeViewsQueryVariables,
  currentUser: Pick<User, 'id'>,
  currentTextContext: TextVariantsContextInterface
) {
  return {
    ...currentTextContext,
    userScope:
      queryVariables?.constraints?.userId?.eq === currentUser.id ? UserScope.SELF : UserScope.OTHER
  };
}

/**
 * Get the sort value depending on the place type
 * @param placeType Type of place selected
 * @param placesSort
 */
function getDefaultSortOrder(
  placeType: PlacesType,
  placesSort: CoreNodeSorts
): CoreNodeSorts | null {
  const isSelectedSortValidForPlaceType = getSortOptionValues(placeType).some(
    sortOption => JSON.stringify(sortOption.value) === JSON.stringify(placesSort)
  );
  return placesSort && isSelectedSortValidForPlaceType
    ? placesSort
    : placeType === PlacesType.GROUPHUB || placeType === PlacesType.MY_GROUPHUB
    ? { membersCount: { direction: SortDirection.Desc } }
    : {
        communityStructure: { direction: SortDirection.Asc, order: 0 },
        position: {
          direction: SortDirection.Asc,
          order: 1
        }
      };
}

const defaultProps = (nodeContext: ContextNodeFragment): PlacesWidgetProps => {
  return {
    viewVariant: {
      type: NodeViewVariant.INLINE,
      props: nodeViewCardInlineDefaults
    },
    panelType: PanelType.STANDARD,
    useTitle: true,
    pagerVariant: {
      type: PagerVariant.LOAD_MORE,
      props: {
        position: PagerPosition.START,
        variant: PagerLoadMoreVariant.NONE
      }
    },
    pageSize: 5,
    nodeDescendantsPageSize: 0,
    hideIfEmpty: true,
    placeType: PlacesType.ALL,
    enablePagination: true,
    nodeScopeId: nodeContext.id
  };
};

/**
 * final props merged with the props passed to component with default props
 * @param props default props for component
 */
export function getFinalProps(
  props: PlacesWidgetProps,
  context: ContextNodeFragment
): PlacesWidgetProps {
  const finalProps = merge(defaultProps(context), props, {
    undefinedMergeBehavior: UndefinedValueMergeBehavior.IGNORE_BEFORE_MERGE,
    mergeNested: false
  });
  finalProps.placesSort = getDefaultSortOrder(finalProps.placeType, finalProps.placesSort);
  return finalProps;
}

export interface PlacesWidgetProps extends NodeListType, ComponentCommonProps, WidgetProps {
  /**
   * Class name(s) to apply to the wrapping element.
   */
  className?: string;
  /**
   * Whether to hide if empty
   */
  hideIfEmpty?: boolean;
  /**
   * The page size of the descendant nodes
   */
  nodeDescendantsPageSize?: number;
  /**
   * Enable pagination for the widget
   */
  enablePagination?: boolean;
  /**
   * Type of place/conversation style to be displayed
   */
  placeType?: PlacesType;
  /**
   * Sort options for places results
   */
  placesSort?: CoreNodeSorts;
  /**
   * Constraints for places results
   */
  placesConstraints?: CoreNodeConstraints;
  /**
   * A component to display when there are no results
   */
  empty?: React.FC<React.PropsWithChildren<unknown>>;

  /**
   * Whether to show title or not
   */
  useTitle?: boolean;

  /**
   * Type of panel to use for widget
   */
  panelType?: PanelType;

  /**
   * The node scope
   */
  nodeScopeId?: CoreNode['id'];
}
/**
 * This component is used to render a list of nodes that are a direct descendant of the context node
 * that is passed as a prop
 * @author Akash Goenka
 */
const PlacesWidget: WidgetFC<PlacesWidgetProps> = ({ isVisible, ...rest }) => {
  const { contextNode, authUser, contextUser } = useContext(AppContext);
  const finalProps = getFinalProps(rest, contextNode);
  const {
    listVariant,
    viewVariant,
    panelType,
    useTitle,
    pagerVariant,
    pageSize,
    nodeDescendantsPageSize,
    className,
    placesSort,
    hideIfEmpty,
    placeType,
    placesConstraints,
    empty,
    enablePagination,
    nodeScopeId
  } = finalProps;

  const cx = useClassNameMapper();
  const textVariantContext = useContext(TextVariantContext);
  const useEmpty = !hideIfEmpty;

  const { pageId: pageName } = useContext(PageContext);
  const [nodeIdForDrawer, setNodeIdForDrawer] = useState<string>('');
  let useMembershipType;
  const { Link } = useEndUserRoutes();
  const user = contextUser ?? authUser;
  const { isAnonymous } = useRegistrationStatus();

  const constraints: CoreNodeConstraints = {
    parentId: { eq: nodeScopeId ?? contextNode.id },
    hidden: { eq: false },
    ...getNodeTypeConstraint(placeType, contextNode, pageName, user)
  };

  if (viewVariant.type === NodeViewVariant.INLINE) {
    useMembershipType =
      ((viewVariant.props as NodeViewInlineProps)?.useLockIcon ||
        viewVariant.props?.useNodeMembersCount) ??
      false;

    viewVariant.props.useUnreadMessagesCount =
      !isAnonymous && viewVariant.props.useUnreadMessagesCount;
  } else {
    useMembershipType = !!(viewVariant.props as NodeViewCardProps)?.useNodeMembershipType;
  }

  const queryVariables: NodeViewsQueryVariables = {
    constraints: { ...constraints, ...placesConstraints },
    sorts: placesSort,
    childrenSorts: placesSort,
    first: pageSize,
    childrenFirst: nodeDescendantsPageSize,
    useNodeParent: true,
    useFullPageInfo: true,
    useMembershipType,
    useChildNodes:
      viewVariant.type === NodeViewVariant.INLINE
        ? nodeDescendantsPageSize > 0
        : viewVariant.props.useChildNodes,
    useNodeLatestActivityTime: viewVariant.props.useNodeLatestActivityTime ?? true,
    useNodeUnreadCount: viewVariant.props.useUnreadMessagesCount ?? true,
    useNodeTopicsCount: viewVariant.props.useNodeTopicsCount ?? true
  };
  const localTextVariantContext = getTextVariantForPlacesQueryVariables(
    queryVariables,
    user,
    textVariantContext
  );
  const { formatMessage } = useTranslation(
    EndUserComponent.PLACES_WIDGET,
    false,
    localTextVariantContext
  );
  const title = formatMessage('title');

  const queryResult = useEntityViewQuery<ItemType.NODE, NodeViewsQuery, NodeViewsQueryVariables>(
    module,
    ItemType.NODE,
    viewVariant,
    nodeViewsQuery,
    {
      variables: queryVariables,
      cacheKey: `${ApolloQueryCacheKey.NODE_STRUCTURE}:${contextNode.id}`
    }
  );
  const { data, loading } = queryResult;

  useEffect(() => {
    if (!useEmpty && !loading) {
      isVisible(data?.coreNodes?.edges?.length !== 0);
    }
  }, [loading, data, isVisible, useEmpty]);

  /**
   * A callback function to render Empty State when entity list has not categories
   */
  function renderNoNodes(): React.ReactElement {
    return (
      <EmptyState alignment={TextAlignment.LEFT} description={formatMessage('emptyDescription')} />
    );
  }

  const nodeViewInlineDefaults: NodeViewInlineProps = {
    nodeIconSize: IconSize.PX_40,
    useChildNodes: nodeDescendantsPageSize > 0,
    nestedNodePager: {
      showNestedNodesPager: nodeDescendantsPageSize > 0 && enablePagination,
      pagerPosition: panelType !== PanelType.STANDARD ? PagerPosition.CENTER : PagerPosition.START
    },
    useNeutralLabel: true,
    childrenSorts: placesSort
  };

  const placesWidgetViewVariant =
    viewVariant.type === NodeViewVariant.INLINE
      ? {
          ...viewVariant,
          props: {
            ...nodeViewInlineDefaults,
            ...viewVariant.props,
            nestedNodesPageSize: nodeDescendantsPageSize,
            useNodeMembersCount:
              placeType === PlacesType.GROUPHUB || placeType === PlacesType.MY_GROUPHUB
                ? viewVariant.props.useNodeMembersCount ?? false
                : false
          }
        }
      : {
          ...viewVariant,
          props: {
            ...viewVariant.props,
            useNodeMembershipType:
              placeType === PlacesType.GROUPHUB || placeType === PlacesType.MY_GROUPHUB
                ? (viewVariant.props as NodeViewCardProps).useNodeMembershipType ?? false
                : false,
            handleNodeViewDrawerAction: (node: NodeBasicFieldsFragment) => {
              if (node) {
                setNodeIdForDrawer(node.id);
              } else {
                setNodeIdForDrawer('');
              }
            },
            nodeViewDrawerId: nodeIdForDrawer
          }
        };

  /**
   * Function that returns pagination props when the type of pager is show all and place type is scoped
   * to display grouphubs
   */
  function getPagerVariantForWidget() {
    if (placeType === PlacesType.MY_GROUPHUB || pageName === EndUserPages.UserPage) {
      return {
        ...pagerVariant,
        type: PagerVariant.SEE_ALL_LINK,
        props: {
          ...pagerVariant.props,
          routeAndParams: {
            route: EndUserPages.GroupHubsPage,
            query: { [EndUserQueryParams.USER_ID]: user.uid.toString() }
          },
          Link
        }
      };
    } else if (placeType === PlacesType.GROUPHUB) {
      switch (pageName) {
        case EndUserPages.CommunityPage: {
          return {
            ...pagerVariant,
            type: PagerVariant.SEE_ALL_LINK,
            props: {
              ...pagerVariant.props,
              routeAndParams: {
                route: EndUserPages.GroupHubsPage
              },
              Link
            }
          };
        }
        case EndUserPages.CategoryPage: {
          return {
            ...pagerVariant,
            type: PagerVariant.SEE_ALL_LINK,
            props: {
              ...pagerVariant.props,
              routeAndParams: {
                route: EndUserPages.GroupHubsPage,
                query: { [EndUserQueryParams.CATEGORY_ID]: contextNode.displayId }
              },
              Link
            }
          };
        }
      }
    }
  }

  function renderHeader(): React.ReactElement {
    return <h3 className={cx('h5 mb-0')}>{title}</h3>;
  }

  const placesWidgetPagerVariant =
    pagerVariant.type === PagerVariant.SEE_ALL_LINK
      ? getPagerVariantForWidget()
      : pageName === EndUserPages.UserPage
      ? pagerVariant ?? getPagerVariantForWidget()
      : { ...pagerVariant };
  return (
    <TextVariantContext.Provider value={localTextVariantContext}>
      <EditableWidget<PlacesWidgetProps> props={finalProps}>
        <PaneledItemList<
          NodeViewFragment,
          ItemType.NODE,
          ItemViewTypeAndProps<ItemType.NODE, NodeViewVariant>,
          NodeViewsQuery,
          NodeViewsQueryVariables
        >
          type={ItemType.NODE}
          header={useTitle && renderHeader}
          headerClassName={cx('lia-g-pb-25')}
          variant={placesWidgetViewVariant}
          panel={panelType}
          queryResult={queryResult}
          pagerVariant={placesWidgetPagerVariant}
          listVariant={listVariant}
          pageSize={pageSize}
          className={cx(className)}
          empty={empty ?? renderNoNodes}
          useEmpty={useEmpty}
          id="PlacesWidget"
        />
      </EditableWidget>
    </TextVariantContext.Provider>
  );
};

export default PlacesWidget;
